import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter
} from 'react-admin';
import { ApproveButton } from '../common/Buttons';
import ApproveBulkButton from './ApproveBulkButton';
import { get } from 'lodash';

const SaleFilter = (props) => (
  <Filter {...props}>
    <TextInput label="По дате" source="saleDate" />
    <TextInput label="По ФИО продавца" source="fio" />
    <TextInput label="По коду партнера" source="code" />
    <TextInput label="По тарифу" source="tariff" />
    <TextInput label="По сумме" source="cost" />
  </Filter>
);

export const SalesNewList = props => {
  return (
    <List
      title='Заведения'
      filters={false}
      bulkActionButtons={false}
      actions={false}
      {...props}
    >
      <Datagrid>
        <TextField source='id' label='ID' sortable={false} />
        <TextField source='name' label='Название' sortable={false} />
        <TextField source='userName' label='Пользователь' sortable={false} />
        <TextField source='userId' label='ID пользователя' sortable={false} />
        <TextField source='cost' label='Выплата' sortable={false} />
      </Datagrid>
    </List>
  );
}
