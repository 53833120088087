import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_CHECK
} from 'react-admin';
import axios from 'axios';
import config from '../configs';

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-API-Key': localStorage.sid
  }
});


export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { phone, phone_code } = params;
    return instance.post(`${config.baseUrl}/session/auth`, {
      phone: phone.replace(/[^0-9+]/gim, ''),
      phoneCode: phone_code
    })
      .then(response => {
        localStorage.sid = response.data.sid;
        localStorage.user_id = response.data.userId;
        return Promise.resolve();
      })
      .catch(err => {
        throw new Error('Нет прав доступа');
      });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('sid');
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('sid');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem('sid')
      ? Promise.resolve()
      : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    return Promise.resolve();
  }
  return Promise.reject('Unknown method');
};
