import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SaleApprove } from './ApproveButton.action';
import styled from 'styled-components';
import { get } from 'lodash';

const Button = styled.button`
  background: none;
  border: none;
  height: 40px;
  color: #8f8f8f;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

class ApproveButton extends Component {
  handleClick = (new_status) => {
    const { SaleApprove, record } = this.props;
    SaleApprove(record.id, record, new_status);
  };

  render() {
    const status = get(this.props, 'record.statusId', 4);
    return (
      <div>
        {(status === 1 || status === 5 || status === 3) &&
        <Button label="Approve" style={{color: 'green'}} onClick={e => this.handleClick(4)}>Выплатить</Button>
        }
        <br />
        {status !== 5 &&
          <Button label="Approve" style={{color: 'red'}}  onClick={e => this.handleClick(5)}>Отменить</Button>
        }
      </div>
    )
  }
}

ApproveButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default connect(null, {
  SaleApprove
})(ApproveButton);
