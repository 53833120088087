import axios from 'axios';
import configs from '../configs';

export default axios.create({
  baseURL: configs.baseURL,
  headers: {
    'Content-Type': 'application/json',
    'X-API-Key': localStorage.sid
  }
});
