import { UPDATE } from 'react-admin';
import { get } from 'lodash';

export const SALE_APPROVE = 'SALE_APPROVE';
export const SaleApprove = (id, data, new_status, basePath) => {
  const saleId = data.id;
  console.log(data);
  return ({
    type: SALE_APPROVE,
    payload: { id, data: { ...data, status: new_status, saleId } },
    meta: {
      fetch: UPDATE,
      resource: 'sale',
      onSuccess: {
        notification: {
          body: (new_status === 4 ) ? 'Продажа успешно подтверждена' : 'Продажа успешно отменена',
          level: 'info',
        },
      },
      onFailure: {
        notification: {
          body: 'Ошибка. Продажа не подтверждена',
          level: 'warning',
        },
      },
      redirectTo: '/sale/result',
      basePath,
      refresh: true
    },
  })
};
