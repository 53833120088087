import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {message, Spin, Upload} from "antd";
import Button from '@material-ui/core/Button';
import {SaleApprove} from './ApproveButton.action';
import config from '../../../configs'
import instance from '../../../Utils/instance.js';

class UploadButton extends Component {

  sendStartTask = () => {
    instance({
      url: `${config.baseUrl}/reports/loadInformation`,
      method: 'post',
      data: {},
    })
  };

  onChange = (info) => {
    if (info.file.status === 'uploading') {
      message.success(`${info.file.name} файл загружается`);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  btnProps = {
    name: 'file',
    action: `${config.baseUrl}/file/uploadFile`,
    fileList: [],
    headers: {
      'X-API-Key': localStorage.sid,
    },
  };

  render() {
    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Button
          primary
          variant='text'
          color='primary'
          size='small'
          style={{marginRight: 20, marginLeft: 20}}
          onClick={this.sendStartTask}
        >
          Загрузить реестр (БД)
        </Button>
      </div>
    )
  }
}

UploadButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default connect(null, {
  SaleApprove
})(UploadButton);
