import React, { Component } from 'react';
import OrganizationsMenu from './OrganizationsMenu/OrganizationsMenu';

class UserMenu extends Component {
  render() {
    const { logout } = this.props;
    return (
      <div style={{ marginLeft: 'auto' }}>
        {/* <OrganizationsMenu /> */}
        {logout}
      </div>
    );
  }
}

export default UserMenu;