import React from 'react';
import {List, Datagrid, TextField, Filter, SelectInput} from 'react-admin';
import { ListActions } from '../common/ListActions';

const choices = [
  {
    name: 'Старые пользователи',
    id: '1'
  },
  {
    name: 'Новые пользователи',
    id: '2',
  }
];

const UserFilter = (props) => (
  <Filter {...props} style={{flexWrap: 'no-wrap', margin: '10px 0px 0px 0px',}}>
    <SelectInput source="userType" choices={choices} alwaysOn label="По пользователям" allowEmpty={false}>
    </SelectInput>
  </Filter>
);


export const UsersList = props => (
    <List
      title='Клиенты'
      actions={<ListActions uploadDisable={true} />}
      filters={<UserFilter />}
      filterDefaultValues={{
        'userType': '1'
      }}
      {...props}
    >
      <Datagrid>
        <TextField source='id' label='ID'/>
        <TextField source='lastName' label='Фамилия'/>
        <TextField source='firstName' label='Имя'/>
        <TextField source='patronymic' label='Отчество'/>
        <TextField source='cityName' label='Город'/>
        <TextField source='partnerCode' label='Код партнера'/>
        <TextField source='companyName' label='Название компании'/>
      </Datagrid>
    </List>
);
