import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Spin} from "antd";
import Button from '@material-ui/core/Button';
import {SaleApprove} from './ApproveButton.action';
import axios from 'axios';
import config from '../../../configs'
import {message} from "antd";

class ConfirmChangeButton extends Component {
  state = {
    loading: false
  };

  confirmAction = () => {
    axios
    .post(`${config.baseUrl}/file/saleChecking/confirm`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': localStorage.sid
      }
    })
    .then(() => {
      this.props.history.push('/sale/result');
    })
    .catch((err) => {
      console.log(err);
      message.error('error')
    })
  }

  render() {
    return (
      <Button
      primary
      variant='text'
      color='primary'
      size='small'
      style={{marginRight: 20, marginLeft: 20}}
      onClick={this.confirmAction}
      >
        <Spin spinning={this.state.loading}></Spin>
        {this.state.loading &&
          'Сохранение...'
        }
        {!this.state.loading &&
          'Сохранить изменения'
        }
      </Button>
    )
  }
}

ConfirmChangeButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default connect(null, {
  SaleApprove
})(ConfirmChangeButton);
