import React from "react";
import {List, Datagrid, TextField } from 'react-admin';

export const PartnersList = props => (
    <List
      title='Партнёры'
      bulkActionButtons={false}
      {...props}
    >
        <Datagrid>
            <TextField source='code' label='Код партнёра' />
            <TextField source='name' label='Наименование' />
            <TextField source='region' label='Регион' />
        </Datagrid>
    </List>
)
