import React from 'react';
import { withRouter } from 'react-router-dom';
import {ExportButton} from "ra-ui-materialui";


const ListOfPayoutActions = (params) => {

  const getItemFromHash = (item) => {
    const paramsOnly = window.location.hash.split('?');
    if (paramsOnly[1]) {
      const paramsAndValue = paramsOnly[1].split('&');
      const paramsAndValueObject = {};
      paramsAndValue.forEach(elem => {
        paramsAndValueObject[elem.split('=')[0]] = elem.split('=')[1]
      });
      return paramsAndValueObject[item]
    }
    return false
  };

  const Statistic = () => (
    <span style={{fontSize: '14px'}}>
      Всего выплат за период на сумму: {getItemFromHash('payoutCount')}
    </span>
  );
  return (
    <div
      style={{
        margin: '10px 0px 0px 0px',
      }}
    >
      { getItemFromHash('payoutCount') &&
        <Statistic/>
      }
      <ExportButton
        disabled={params.total === 0}
        resource={params.resource}
        sort={params.currentSort}
        filter={params.filterValues}
        exporter={params.exporter}
        icon={null}
        variant='text'
        color='primary'
        size='small'
        style={{ marginRight: 20 }}
        maxResults={100000}
      />
    </div>
  )
};

export default withRouter(ListOfPayoutActions);
