import styled from 'styled-components';
import defaultTheme from '../../Themes/defaultTheme';

export const Layout = styled.div`
  min-height: 100vh;
  width: 100%;
  min-height: 100vh;
  width: 100%;
  background: ${defaultTheme.overrides.LoginLayout.background};
`;

export const LogoContainer = styled.div`
  padding-top: 20px;
`;

export const Logo = styled.div`
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 4.25;
  letter-spacing: normal;
  color: #ffffff;
`;

export const Title = styled.div`
  padding-top: 50px;
  padding-bottom: 30px;
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #222328;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const FormContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 50px;
`;

export const InputsContainer = styled.div`
  padding: 0 60px;
  @media (max-width: 800px) {
    padding: 0;
  }
`;

export const InputsContainerPhone = styled.div`
  padding: 30px 60px 60px;
  @media (max-width: 800px) {
    padding: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 30px;
`;

export const ButtonContainerPhone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 0 0;
`;

export const ErrorContaner = styled.div`
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #fae7ec;
`;