import { createMuiTheme } from "@material-ui/core/styles";

export const qrbarTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#4A74FF"
    },
    secondary: {
      main: "#fff"
    },
    error: {
      main: "#ff4646"
    },
    textPrimary: {
      main: "#222328"
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif"
    ].join(",")
  },
  overrides: {
    LoginLayout: {
      background: '#E5E7EC'
    },
    MuiTypography: {
      title: {
        fontSize: 28,
        fontWeight: 900,
      },
      display1: {
        fontSize: 14,
        textTransform: "uppercase",
        fontWeight: 500
      },
      body1: {
        fontSize: 12,
        color: '#8f8f8f'
      },
      display3: {
        fontSize: 18,
        fontWeight: 900,
      }
    },
    MuiCheckbox: {
      root: {
        borderRadius: 5
      }
    },
    MuiInput: {
      root: {
        background: "#eff0f4",
        borderRadius: 4,
        padding: "0px 14px",
        minHeight: 40,
        boxSizing: "border-box",
        "&:after": {
          display: "none"
        },
        "&:before": {
          display: "none"
        }
      },
      focused: {
        boxShadow: "0 9px 18px 0 rgba(173, 178, 199, 0.62)",
        background: "#ffffff"
      },
      inputType: {
        minHeight: "1.8em"
      }
    },
    MuiInputLabel: {
      root: {
        transform: "translate(0, 0px) scale(1)",
        fontSize: 10,
        textTransform: "uppercase",
        paddingLeft: 15,
        boxSizing: "border-box"
      },
      formControl: {
        transform: "translate(0, 0px) scale(1)"
      },
      shrink: {
        transform: "translate(0, 0px) scale(1)"
      }
    },
    MuiDrawer: {
      docked: {
        backgroundColor: "#e5e7ec",
        minHeight: "100vh"
      }
    },
    MuiCard: {
      root: {
        boxShadow: "none",
        overflow: ""
      }
    },
    Layout: {
      root: {
        backgroundColor: "#fff"
      },
      content: {
        paddingTop: 0
      }
    },
    MuiAppBar: {
      root: {
        boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)"
      }
    },
    MuiButton: {
      root: {
        borderRadius: 4,
        height: "30px",
        fontFamily: "Roboto",
        fontSize: "10px",
        letterSpacing: "1px",
        textAlign: "center",
        fontWeight: 900
      },
      containedPrimary: {
        color: "#ffffff",
        "&:hover": {
          color: "#ffffff",
        }
      },
      outlinedSecondary: {
        backgroundColor: "transparent",
        border: "1px solid #ff4646",
        color: "#ff4646",
        "&:hover": {
          border: "1px solid #ff4646"
        }
      },
      sizeSmall: {
        padding: '5px 8px'
      }
    },
    MuiTab: {
      root: {
        borderBottom: "none",
        "&:focus": { textDecoration: "none" },
        "& hr": { display: "none" },
        "&:hover": { color: "#4b74ff" }
      },
      selected: {
        color: "#4b74ff",
        "&:hover": { color: "#4b74ff" }
      }
    },
    MuiSwitchBase: {
      // checked: {
      //   backgroundColor: "#15b4f1",
      //   width: 60,
      //   height: 30,
      //   borderRadius: 20,
      // }
    },
    MuiTablePagination: {
      input: {
        height: 30,
        padding: 0,
        background: 'transparent'
      },
      select: {
        lineHeight: '16px',
        paddingRight: 26
      }
    },
    MuiSelect: {
      root: {
        lineHeight: '27px',
        fontSize: '14px'
      },
      select: {
        '&:focus': {
          background: 'transparent'
        }
      }
    },
    MuiRadio: {
      root: {
        height: 34
      }
    },
    MuiDialogActions: {
      root: {
        paddingBottom: 10
      }
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          color: '#000000',
        },
        '&:focus': {
          color: '#000000',
          '& $primary, & $icon': {
            color: '#000000',
          },
        },
        '&:hover': {
          color: '#000000',
          '& $primary, & $icon': {
            color: '#000000',
          },
        },
      }
    }
  }
});