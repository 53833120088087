import { settings as superadmin } from './superadmin';
import { settings as admin } from './admin';
import { settings as qrbaradmin } from './qrbaradmin';
import { settings as atol } from './atol';
import { settings as atol_dev } from './atol_dev';

let config = {};

if (process.env.PROJECT === 'superadmin') {
  config = superadmin;
} else if (process.env.PROJECT === 'admin') {
  config = admin;
} else if (process.env.PROJECT === 'qrbar') {
  config = qrbaradmin;
} else if (process.env.PROJECT === 'atol') {
  config = atol;
} else if (process.env.PROJECT === 'atol_dev') {
  config = atol_dev;
}


export default config;
