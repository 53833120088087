import React from 'react';
import { Admin, Resource } from 'react-admin';
import defaultTheme from './Themes/defaultTheme';
import RestProvider from './Utils/RestProvider';
import authProvider from './Utils/authProvider';
import {
  SalesList,
} from './Components/Sales';
import {
  SalesNewList,
} from './Components/SalesNew';
import {
  AccrualsList,
} from './Components/Accruals/Accruals';
import {
  UsersList,
} from './Components/Users';
import {
  StatisticsList,
} from './Components/Statistics';
import {
  PartnersList
} from './Components/Partners';
import {StatisticsPayoutList} from "./Components/StatisticsPayout/StatisticsPayoutList";
import { StatisticsSaleList } from "./Components/StatisticsSales/StatisticsSalesList";
import { ConfirmList } from './Components/Sales/SalesConfirmChanges'
import { Menu } from './Components/Menu';
import Login from './Components/Login';
import { LogoutButton } from './Components/Logout';
import { Layout } from './Components/Layout';
import russian from './i18n/russian';
import config from './configs';
import currentOrganization from './Components/AppBar/OrganizationsMenu/OrganizationsMenu.reducer';
//  favicon
import Favicon from 'react-favicon';

const messages = {
  'ru': russian,
};
const i18nProvider = locale => messages[locale];

const restProvider = RestProvider(config.baseUrl);

const customRoutes = [];

const App = () => {
  return (
    <div>
      <Favicon url={config.favIcon} />
      <Admin
        dataProvider={restProvider}
        authProvider={authProvider}
        theme={defaultTheme}
        appLayout={Layout}
        customRoutes={customRoutes}
        menu={Menu}
        loginPage={Login}
        logoutButton={LogoutButton}
        locale="ru"
        i18nProvider={i18nProvider}
        customReducers={{ currentOrganization }}
      >
        <Resource
          name="sale/result"
          options={{ label: 'Заведения' }}
          list={SalesList}
          create={ConfirmList}
        />
        <Resource
          name="newAccrual/history"
          options={{ label: 'Заведения' }}
          list={SalesNewList}
          create={ConfirmList}
        />
        <Resource
          name="balance/history"
          options={{ label: 'Выплаты' }}
          list={AccrualsList}
          // create={ConfirmList}
        />
        <Resource
          name="user/all"
          options={{ label: 'Пользователи' }}
          list={UsersList}
        />
        <Resource
          name="partners"
          options={{ label: 'Партнеры' }}
          list={PartnersList}
        />
        <Resource
          name="user/registration"
          options={{ label: 'Статистика пользователи' }}
          list={StatisticsList}
        />
        <Resource
          name="sale/statistic"
          options={{ label: 'Статистика продажи' }}
          list={StatisticsSaleList}
        />
        <Resource
          name="balance/transaction"
          options={{ label: 'Статистика выплаты' }}
          list={StatisticsPayoutList}
        />
      </Admin>
    </div>
  );

};

export default App;
