import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter, DateInput, SelectInput
} from 'react-admin';
import ApproveBulkButton from './ApproveBulkButton';
import ListOfSalesActions from './ListOfSalesActions'
import { get } from 'lodash';

const choices = [
  {
    name: 'Атол Сигма',
    id: '1'
  },
  {
    name: 'Атол Сервис',
    id: '2',
  }
];

const SaleFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="destination" choices={choices} allowEmpty={false} alwaysOn label="По направлению">
    </SelectInput>
    <DateInput alwaysOn label="По дате от" source="dateFrom" />
    <DateInput alwaysOn label="По дате до" source="dateTo" />
  </Filter>
);

const PostBulkActionButtons = props => (
  <Fragment>
    <ApproveBulkButton {...props}/>
  </Fragment>
);

const FioField = ({ source, record = {} }) => {
  const data = get(record, source);
  return (
    <span>
      {`${data}`}
    </span>
  );
};

const TimeField = ({ source, record = {} }) => {
  const time = get(record, source);
  return (
    <span>
      {time}
    </span>
  );
};



export const StatisticsSaleList = props => {
  return (
  <List
  title='Заведения'
  filters={<SaleFilter />}
  filterDefaultValues={{
    destination: '1',
  }}
  bulkActionButtons={false}
  actions={<ListOfSalesActions/>}
  {...props}
  >
    <Datagrid>
      <TextField sortable={false} source='id' label='ID' />
      <TimeField sortable={false} source='userName' label='ФИО' />
      <TimeField sortable={false} source='saleDate' label='Дата/Время' />
      <TextField sortable={false} source='tariff' label='Тариф' />
      <TextField sortable={false} source='serialNumber' label='Номер кассы' />
      <TextField sortable={false} source='cost' label='Сумма' />
      <TextField sortable={false} source='status' label='Статус' />
      <TextField sortable={false} source='userLogin' label='Логин' />
      <TextField sortable={false} source='phoneNumber' label='Номер телефона' />
    </Datagrid>
  </List>
  );
}
