import React, { createElement } from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import config from '../../configs'


const Menu = ({ resources, onMenuClick }) => (
  <div>
    {
      config.menu.map((settings, i) => {
        return (<MenuItemLink
          key={i}
          onClick={onMenuClick}
          {...settings}
        />)
      })
    }
  </div>
);

const mapStateToProps = state => ({
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(Menu));