import React from 'react';
import Button from '@material-ui/core/Button';
import { CardActions, ExportButton, RefreshButton, CreateButton } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { UploadButton } from '../../common/Buttons'
import { ShowVerificationButton } from '../../common/Buttons'
import config from '../../../configs'


const ListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  history,
  uploadDisable,
  isLoading,
  ...rest
}) => {

  const state = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  };

  return (
    <div
      style={{
        margin: '10px 0px 0px 0px',
        width: '100%',
      }}
    >
      <CardActions>
        {/* <Typography variant='display3' style={{ marginRight: 'auto' }}>Продажи: 10</Typography> */}
        {bulkActions && React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
          size: 'medium',
          disabled: true
        })}
        {!uploadDisable &&
          <UploadButton history={history}/>
        }
        {!uploadDisable &&
          <ShowVerificationButton history={history}/>
        }
        {uploadDisable &&
          <Button
            primary
            variant='text'
            color='primary'
            size='small'
            href={`${config.baseUrl}/download/report/analytics/${state.year}`}
            target='_blank'
          >
            Скачать отчет
          </Button>
        }
        {uploadDisable &&
          <Button
            primary
            variant='text'
            color='primary'
            size='small'
            href={`${config.baseUrl}/download/report/analyticsComplex/${state.year}/${state.month}`}
            target='_blank'
            >
            Скачать комплексный отчет
          </Button>
        }
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
          icon={null}
          variant='text'
          color='primary'
          size='small'
          style={{ marginRight: 20 }}
          maxResults={100000}
        />
        <RefreshButton />
      </CardActions>
    </div>
  )
};

export default withRouter(ListActions);
