import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import { MuiThemeProvider } from '@material-ui/core/styles';
import defaultTheme from '../../Themes/defaultTheme';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ButtonBase from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import {
  LogoContainer,
  Layout,
  Logo,
  Title,
  Container,
  FormContainer,
  InputsContainerPhone,
  ButtonContainerPhone,
  ErrorContaner,
} from './styled';
import instance from '../../Utils/instance';
import config from '../../configs';
import InputMask from 'react-input-mask';

class MyLoginPage extends Component {
  state = {
    phone: '',
    phone_error: '',
    codeSended: false
  };

  submit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    const credentials = {
      phone: this.state.phone,
      phone_code: data.get('code'),
    };

    this.props.userLogin(credentials);
  };

  onChangePhone = (e) => {
    this.setState({
      phone: e.target.value
    })
  };

  onSubmitePhone = (e) => {
    e.preventDefault();
    const { phone } = this.state;
    instance
      .post(`${config.baseUrl}/session/request`, {
        phone: phone.replace(/[^0-9+]/gim, '')
      })
      .then(response => {
        this.setState({
          codeSended: true,
          phone_error: false
        })
      })
      .catch(err => {
        this.setState({
          phone_error: err
        })
      });
  };

  onSubmitCode = () => {

  };

  renderLogo = (logo) => {
    if (typeof logo === 'string') {
      return <Logo>{logo}</Logo>
    } else {
      return <LogoContainer>{logo}</LogoContainer>;
    }
  };

  render() {
    const { admin } = this.props;
    const { phone_error, phone } = this.state;
    const error_message = get(admin, 'notifications[0].message', '');
    return (
      <MuiThemeProvider theme={defaultTheme}>
        <Layout>
          <Container>
            {this.renderLogo(config.logo)}
            <FormContainer>
              <Paper styled={{ maxWidth: 800 }}>
                <Title>Вход в аккаунт</Title>
                {!this.state.codeSended &&
                  <div>
                    <Typography align='center' variant=''>Укажите номер вашего телефона и нажмите "Далее"</Typography>
                    <Typography align='center' variant=''>Мы вышлем вам код для подтверждения</Typography>
                  </div>
                }
                {this.state.codeSended &&
                  <div>
                    <Typography align='center' variant=''>Мы отправили код подтверждения на номер {phone}</Typography>
                  </div>
                }
                <InputsContainerPhone>
                  {!this.state.codeSended &&
                    <form onSubmit={e => this.onSubmitePhone(e)}>
                      <Grid container justify="center" spacing={24}>
                        <Grid item xs={12} sm={5}>
                          <InputMask
                            mask="+7 (999) 999-99-99"
                            value={this.state.phone}
                            onChange={this.onChangePhone}
                          >
                            {() => <TextField
                              fullWidth={true}
                              label='Номер телефона'
                              autoFocus={true}
                              name='phone'
                            />}
                          </InputMask>
                        </Grid>
                        <Grid item justify="center" xs={12} sm={2}>
                          <ButtonContainerPhone>
                            <ButtonBase
                              variant="contained"
                              color="primary"
                              type='submit'
                              fullWidth={true}
                            >
                              Далее
                            </ButtonBase>
                          </ButtonContainerPhone>
                        </Grid>
                      </Grid>
                    </form>
                  }
                  {this.state.codeSended &&
                    <form onSubmit={e => this.submit(e)}>
                      <Grid container justify="center" spacing={24}>
                        <Grid item xs={12} sm={5}>
                          <TextField
                            name='code'
                            fullWidth={true}
                            label='Код из СМС'
                            autoFocus={true}
                          />
                        </Grid>
                        <Grid item justify="center" xs={12} sm={2}>
                          <ButtonContainerPhone>
                            <ButtonBase
                              variant="contained"
                              color="primary"
                              type='submit'
                              fullWidth={true}
                            >
                              Отправить
                          </ButtonBase>
                          </ButtonContainerPhone>
                        </Grid>
                      </Grid>
                    </form>
                  }
                </InputsContainerPhone>
                {phone_error &&
                  <div>
                    <ErrorContaner>
                      Ошибка входа: Не верный номер телефона. Введите номер в формате +79991234567.
                    </ErrorContaner>
                    <div style={{ height: 30 }}></div>
                  </div>
                }
                {/* {error_message &&
                  <div>
                    <ErrorContaner>
                      Ошибка входа: {get(admin, 'notifications[0].message', '')}
                    </ErrorContaner>
                    <div style={{ height: 30 }}></div>
                  </div>
                } */}
                <Typography align='center' variant='' style={{ paddingBottom: 40 }}>
                  {'Вводя код из СМС вы соглашаетесь с условиями '}
                  <a style={{ color: '#EC1C30' }} href='https://atol-api.qrbonus.ru/file/rules' target='_blank'>
                    пользовательского соглашения
                    </a>
                </Typography>
                {error_message &&
                  <div>
                    <ErrorContaner>
                      Ошибка входа: {get(admin, 'notifications[0].message', '')}
                    </ErrorContaner>
                    <div style={{height: 30}}></div>
                  </div>
                }
              </Paper>
            </FormContainer>
          </Container>
        </Layout>
      </MuiThemeProvider>
    );
  }
};

function mapStateToProps(state) {
  return {
    admin: state.admin
  }
}

export default connect(mapStateToProps, { userLogin })(MyLoginPage);
