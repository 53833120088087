import React from 'react';
import {ConfirmChangeButton} from "../../common/Buttons";
import { CardActions, RefreshButton } from 'react-admin';
import Button from '@material-ui/core/Button';

import { withRouter } from 'react-router-dom';

const Actions = ({
                   bulkActions,
                   basePath,
                   currentSort,
                   displayedFilters,
                   exporter,
                   filters,
                   filterValues,
                   onUnselectItems,
                   resource,
                   selectedIds,
                   showFilter,
                   total,
                   history,
                   uploadDisable,
                   ...rest
                 }) => {
  return (
    <CardActions>
      <Button
        primary
        variant='text'
        color='primary'
        size='small'
        onClick={() => history.push('/sale/result')}
        target='_blank'
      >
        Отменить
      </Button>
      <ConfirmChangeButton history={history}/>
      <RefreshButton/>
    </CardActions>
  )
};

export default withRouter(Actions)
