export default {
  ra: {
    resourses: {
      sale: {
        notification: {
          approved_success: 'Успешно'
        }
      }
    },
    action: {
      delete: 'Выплатить',
      show: 'Просмотр',
      list: 'Список',
      save: 'Сохранить',
      create: 'Добавить',
      edit: 'Редактировать',
      sort: 'Сортировать',
      cancel: 'Отмена',
      undo: 'Отменить',
      refresh: 'Обновить',
      add_filter: 'Фильтры',
      remove_filter: 'Убрать фильтр',
      back: 'Назад',
      bulk_actions: '%{smart_count} выбрано',
      add: 'Добавить',
      remove: 'Выплатить',
      search: 'Найти',
      clear_input_value: 'Очистить',
      clone: 'Дублировать',
      export: 'Экспортировать CSV',
    },
    boolean: {
      true: 'Да',
      false: 'Нет',
    },
    page: {
      list: '%{name}',
      edit: '%{name} #%{id}',
      show: '%{name} #%{id}',
      create: 'Создать %{name}',
      delete: 'Удалить %{name} #%{id}',
      dashboard: 'Главная',
      not_found: 'Страница не найдена',
      loading: 'Загрузка',
      error: 'Что-то пошло не так',
    },
    input: {
      file: {
        upload_several: 'Перетащите файлы сюда или нажмите для выбора.',
        upload_single: 'Перетащите файл сюда или нажмите для выбора.',
      },
      image: {
        upload_several: 'Перетащите изображения сюда или нажмите для выбора.',
        upload_single: 'Перетащите изображение сюда или нажмите для выбора.',
      },
      references: {
        all_missing: 'Не удалось найти связанных данных',
        many_missing:
          'Недоступна как минимум одна ссылка на данные',
        single_missing:
          'Выбранная ссылка больше недоступна',
      }
    },
    message: {
      yes: 'Да',
      no: 'Нет',
      are_you_sure: 'Вы уверены?',
      about: 'Справка',
      not_found: 'Вы набрали неверный URL-адрес или перешли по некорректной ссылке',
      loading: 'Страница загружается',
      invalid_form: 'В форме обнаружены ошибки',
      delete_title: 'Удалить %{name} #%{id}',
      delete_content: 'Вы уверены, что хотите удалить?',
      details: 'Детальная информация',
      error: "Произошла ошибка и ваш запрос не может быть обработан",
      bulk_delete_title:
        'Удалить %{name} |||| Удалить %{smart_count} %{name}',
      bulk_delete_content:
        'Вы уверены, что хотите удалить %{name}? |||| Вы уверены, что хотите удалить %{smart_count} %{name}?',
    },
    navigation: {
      no_results: 'Данные отсутствуют.',
      page_out_of_boundaries: 'Страница %{page} вне границ',
      page_out_from_end: 'Невозможно переместиться дальше последней страницы',
      page_out_from_begin: 'Номер страницы не может быть меньше 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} из %{total}',
      page_rows_per_page: 'Кол-во на странице:',
      next: 'Следующая',
      prev: 'Предыдущая',
    },
    auth: {
      username: 'Имя пользователя',
      user_menu: 'Профиль',
      password: 'Пароль',
      sign_in: 'Войти',
      sign_in_error: 'Ошибка аутентификации, попробуйте снова',
      logout: 'Выйти',
    },
    notification: {
      updated: 'Продажи подтверждены',
      created: 'Элемент создан',
      deleted: 'Элемент удален',
      bad_item: 'Проблемы с элементом',
      item_doesnt_exist: 'Элемент не существует',
      http_error: 'Ошибка сервера',
      canceled: 'Операция отменена',
      data_provider_error: 'Ошибка в сервисе данных. Проверьте консоль',
    },
    validation: {
      required: 'Обязательно для заполнения',
      minLength: 'Минимальное кол-во символов %{min}',
      maxLength: 'Максимальное кол-во символов %{max}',
      minValue: 'Минимальное значение %{min}',
      maxValue: 'Значение может быть %{max} или меньше',
      number: 'Должно быть цифрой',
      email: 'Некорректный email',
      oneOf: 'Должно быть одно из: %{options}',
      regex: 'Должно соответствовать формату (regexp): %{pattern}',
    },
  },
};
