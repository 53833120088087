import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import { MuiThemeProvider } from '@material-ui/core/styles';
import defaultTheme from '../../Themes/defaultTheme';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ButtonBase from '@material-ui/core/Button';
import { get } from 'lodash';
import {
  LogoContainer,
  Layout,
  Logo,
  Title,
  Container,
  FormContainer,
  InputsContainer,
  ButtonContainer,
  ErrorContaner,
} from './styled';
import config from '../../configs';

class MyLoginPage extends Component {
  submit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    const credentials = {
      username: data.get('username'),
      password: data.get('password'),
    };

    this.props.userLogin(credentials);
  };

  renderLogo = (logo) => {
    if (typeof logo === 'string' ) {
      return <Logo>{logo}</Logo>
    } else {
      return <LogoContainer>{logo}</LogoContainer>;
    }
  };

  render() {
    const { admin } = this.props;
    const error_message = get(admin, 'notifications[0].message', '');
    console.log(this.props);
    console.log('config.project:'+config.project);
    return (
      <MuiThemeProvider theme={defaultTheme}>
        <Layout>
          <Container>
            {this.renderLogo(config.logo)}
            <FormContainer>
              <Paper styled={{ maxWidth: 800 }}>
                <Title>Вход в панель управления</Title>
                <InputsContainer>
                  <form onSubmit={this.submit}>
                    <Grid container justify="space-between" spacing={24}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name='username'
                          fullWidth={true}
                          label='e-mail'
                          autoFocus={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name='password'
                          type='password'
                          fullWidth={true}
                          label='Пароль'
                        />
                      </Grid>
                    </Grid>
                    <ButtonContainer>
                      <ButtonBase
                        variant="contained"
                        color="primary"
                        type='submit'
                      >
                        Войти
                      </ButtonBase>
                    </ButtonContainer>
                  </form>
                </InputsContainer>
                {error_message &&
                  <div>
                    <ErrorContaner>
                      Ошибка входа: {get(admin, 'notifications[0].message', '')}
                    </ErrorContaner>
                    <div style={{height: 30}}></div>
                  </div>
                }
              </Paper>
            </FormContainer>
          </Container>
        </Layout>
      </MuiThemeProvider>
    );
  }
};

function mapStateToProps(state) {
  return {
    admin: state.admin
  }
}

export default connect(mapStateToProps, { userLogin })(MyLoginPage);
