import { stringify } from 'query-string';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import { get } from 'lodash';
import config from '../configs';
import instance from './instance';
import moment from 'moment';

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (API_URL) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    const resourceConfig = get(config, `resources[${resource}]`, false);
    const RESOURCE_URL = (resourceConfig) ? resourceConfig(resource) : resource;
    const API_URL = get(config, 'baseUrl', '');
    let url = '';
    let options = {
      headers: {
        'X-API-Key': localStorage.sid
      }
    };
    switch (type) {
      case GET_LIST: {
        if (resource === 'user/all' && params.filter.userType) {
          options.headers['User-Type'] = params.filter.userType
        }
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          sort: field,
          order: order,
          page: page -1,
          perPage,
          filter: JSON.stringify(params.filter),
          extra: 1
        };
        if (window.location.href.indexOf('/sale/result/create') + 1) {
          url = `${API_URL}/file/saleChecking/getSalesForConfirm?${stringify(query)}`
        } else if (window.location.href.indexOf('/user/registration') + 1 && params.filter.dateFrom && params.filter.dateTo) {
          url = `${API_URL}/user/registration/${params.filter.dateFrom}/${params.filter.dateTo}?${stringify(query)}`;
        } else if (window.location.href.indexOf('/sale/statistic') + 1 && params.filter.dateFrom && params.filter.dateTo && params.filter.destination) {
          url = `${API_URL}/sale/statistic/${params.filter.dateFrom}/${params.filter.dateTo}?destination=${params.filter.destination}&${stringify(query)}`;
        } else if (window.location.href.indexOf('/balance/transaction') + 1 && params.filter.dateFrom && params.filter.dateTo) {
          url = `${API_URL}/balance/transaction/${params.filter.dateFrom}/${params.filter.dateTo}?${stringify(query)}`;
        } else  if (window.location.href.indexOf('/balance/history')) {
          url = `${API_URL}/${RESOURCE_URL}?${stringify(query)}`.replace('balance/history', 'balance/history/web');
        } else {
          url = `${API_URL}/${RESOURCE_URL}?${stringify(query)}`;
        }
        break;
      }
      case GET_ONE:
          url = `${API_URL}/${RESOURCE_URL}/${params.id}?sid=${localStorage.sid}`;
        break;
      case GET_MANY: {
        const query = {
          filter: JSON.stringify({ id: params.ids }),
          sid: localStorage.sid
        };
        url = `${API_URL}/${RESOURCE_URL}?${stringify(query)}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          sort: JSON.stringify([field, order]),
          page,
          limit: perPage,
          filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
          }),
        };
        url = `${API_URL}/${RESOURCE_URL}?${stringify(query)}`;
        break;
      }
      case UPDATE:
        url = `${API_URL}/sale/update`;
        options.method = 'PATCH';
        // TEMP
        // TEMP
        options.data = params.data;
        break;
      case CREATE:
        url = `${API_URL}/${RESOURCE_URL}?sid=${localStorage.sid}`;
        options.method = 'POST';
        options.data = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${API_URL}/${RESOURCE_URL}/${params.id}`;
        options.method = 'DELETE';
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
      const {data} = response;
      switch (type) {
        case GET_LIST:
          if ((resource === 'sale/result') && window.location.href.indexOf('/sale/result/create') === -1) {
            const dataForTable = data.content || [];
            const formatData = [];
            dataForTable.forEach(elem => {
              const newRow = {
                id: elem.id,
                saleDate: elem.saleDate ? moment(elem.saleDate).format('DD-MM-YYYY hh:mm') : '-',
                userName: `${elem.userProfile.lastName} ${elem.userProfile.firstName} ${elem.userProfile.patronymic}`,
                partnerCode: elem.userProfile.partnerCode,
                plan: elem.tariff.plan,
                cost: elem.cost,
                checkSaleComment: elem.checkSaleComment || '-',
                serialNumber: elem.serialNumber,
                status: elem.status.status,
                statusId: elem.status.id
              };
              formatData.push(newRow);
            });
            return {
              data: formatData,
              total: data.totalElements
            }
          } else if (resource === 'newAccrual/history' || resource === 'accrual/history') {
            const dataForTable = data.content || [];
            const formatData = [];
            dataForTable.forEach(elem => {
              const newRow = {
                id: elem.id,
                name: elem.offerDescribe && elem.offerDescribe.name || '-',
                userName: elem.userProfile && `${elem.userProfile.firstName} ${elem.userProfile.lastName}` || '-',
                userId: elem.activatedUserId || '-',
                cost: elem.cost || '-',
              };
              formatData.push(newRow);
            });
            return {
              data: formatData,
              total: data.totalElements
            }
          } else if (resource === 'balance/history') {
            const dataForTable = data.content || [];
            const formatData = [];
            dataForTable.forEach(elem => {
              const newRow = {
                ...elem,
                fullName: `${elem.userProfile.lastName} ${elem.userProfile.firstName} ${elem.userProfile.patronymic}`,
              };
              formatData.push(newRow);
            });
            return {
              data: formatData,
              total: data.totalElements
            }
          } else if (resource === 'user/registration') {
            if (response.headers.count_users) {
              if (!(window.location.hash.indexOf('usersCount') + 1)) {
                if (window.location.hash.indexOf('?') + 1) {
                  window.location.hash = `${window.location.hash}&usersCount=${response.headers.count_users}`
                } else {
                  window.location.hash = `${window.location.hash}?usersCount=${response.headers.count_users}`
                }
              }
            }
            return {
              data: data.content,
              total: data.totalElements,
            };
          } else if (resource === 'sale/statistic') {
            if (response.headers.count_sales) {
              if (!(window.location.hash.indexOf('salesCount') + 1)) {
                if (window.location.hash.indexOf('?') + 1) {
                  window.location.hash = `${window.location.hash}&salesCount=${response.headers.count_sales}`
                } else {
                  window.location.hash = `${window.location.hash}?salesCount=${response.headers.count_sales}`
                }
              }
            }
            const dataForTable = data.pageList || [];
            console.log('######')
            console.log(dataForTable)
            console.log('######')
            const formatData = [];
            dataForTable.forEach(elem => {
              const newRow = {
                id: elem.saleId,
                saleDate: elem.saleDate ? moment(elem.saleDate).format('DD-MM-YYYY hh:mm') : '-',
                userName: elem.userFullName,
                cost: elem.cost,
                status: elem.status,
                userLogin: elem.userLogin,
                phoneNumber: elem.phoneNumber,
                tariff: elem.tariff,
                serialNumber: elem.serialNumber,
              };
              formatData.push(newRow);
            });
            console.log('######')
            console.log(formatData)
            console.log('######')
            return {
              data: formatData,
              total: data.pageCount
            }
          } else if (resource === 'balance/transaction') {
            if (response.headers.sum_payout) {
              if (!(window.location.hash.indexOf('payoutCount') + 1)) {
                if (window.location.hash.indexOf('?') + 1) {
                  window.location.hash = `${window.location.hash}&payoutCount=${response.headers.sum_payout}`
                } else {
                  window.location.hash = `${window.location.hash}?payoutCount=${response.headers.sum_payout}`
                }
              }
            }
            const dataForTable = data.content || [];
            const formatData = [];
            dataForTable.forEach(elem => {
              const newRow = {
                id: elem.id,
                cardNumber: elem.cardNumber,
                saleDate: elem.saleDate ? moment(elem.saleDate).format('DD-MM-YYYY hh:mm') : '-',
                userName: `${elem.userProfile.lastName} ${elem.userProfile.firstName} ${elem.userProfile.patronymic}`,
                partnerCode: elem.userProfile.partnerCode,
                amount: elem.amount,
                serialNumber: elem.serialNumber,
                status: elem.status === 1 ? 'Выплачено' :
                    elem.status === 7 ? 'Обнуление баланса' :
                    elem.status === 3 ? `Отказано, ${elem.comment && elem.comment.toLowerCase()}` :
                    elem.status === 2 ? `Ошибка, ${elem.comment && elem.comment.toLowerCase()}` :
                      'В обработке',
                destination: elem.destination,
                comment: elem.comment,
              };
              formatData.push(newRow);
            });
            return {
              data: formatData,
              total: data.totalElements
            }
          } else if (window.location.href.indexOf('/sale/result/create') + 1) {
            const dataForTable = data || [];
            const formatData = [];
            dataForTable.forEach(elem => {
              const newRow = {
                id: elem.id,
                saleDate: elem.saleDate ? moment(elem.saleDate).format('DD-MM-YYYY hh:mm') : '-',
                userName: `${elem.userProfile.lastName} ${elem.userProfile.firstName} ${elem.userProfile.patronymic}`,
                partnerCode: elem.userProfile.partnerCode,
                plan: elem.tariff.plan,
                cost: elem.cost,
                serialNumber: elem.serialNumber,
                status: elem.status.status,
                statusId: elem.status.id,
                potentialStatus: elem.potentialStatus.status,
                potentialStatusId: elem.potentialStatus.id,
                statusComment: elem.checkSaleComment || ''
              };
              formatData.push(newRow);
            });
            return {
              data: formatData,
              total: formatData.length
            }
          } else if (window.location.href.indexOf('/partners') + 1) {
            const dataForTable = data.pageList || [];
            const formatData = [];
            dataForTable.forEach((elem, index) => {
              const newRow = {
                id: index,
                code: elem.code,
                name: elem.name,
                region: elem.region,
              };
              formatData.push(newRow);
            });
            return {
              data: formatData,
              total: data.nrOfElements
            }
          } else if (window.location.href.indexOf('/user/all') + 1) {
            return {
              data: data.content.map(el => ({
                ...el,
                partnerCode: el.partnerCode + ' / ' + el.login,
              })),
              total: data.totalElements,
            }
          }
          return {
            data: data,
            total: 1000
          };
        case GET_MANY_REFERENCE:
        case GET_MANY:
          if (resource === 'user/all') {
            return {
              data: data.content,
              total: data.totalElements,
            }
          }
          return {
            data: data.content,
            total: data.totalElements
          };
        case GET_ONE:
          return {data};
        case CREATE:
          return {data};
        case UPDATE:
          return {data};
        default:
          return {data: data.items};
      }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          instance(`${API_URL}/sale/update`, {
            method: 'PATCH',
            data: {
              saleId: id,
              status: 4
            },
          })
        )
      ).catch(err => {
        if (err.response.status === 409) {
          err.message = 'Данные о продаже еще не поступили из базы Атол'
        }
        throw err;
      }).then(responses => ({
        data: responses.map(response => response.json),
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          instance(`${API_URL}${resource}/${id}`, {
            method: 'DELETE',
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }));
    }
    const { url, options } = convertDataRequestToHTTP(
      type,
      resource,
      params
    );
    if (type === UPDATE) {
      return instance(url, options)
        .catch(err => {
          if (err.response.status === 409) {
            err.message = 'Данные о продаже еще не поступили из базы Атол'
          }
          throw err;
        }).then(response =>
          convertHTTPResponse(response, type, resource, params)
        );
    }
    if (type === GET_LIST) {
      if (resource === 'user/registration' || resource === 'sale/statistic' || resource === 'balance/transaction') {
        if (params.filter.dateFrom && params.filter.dateTo) {
          return instance(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params)
          );
        } else {
          return {data: [], total: 0}
        }
      }
    }
    if (params.filter) {
      if (!params.filter.sn || params.filter.sn.length > 4) {
        return instance(url, options).then(response =>
        convertHTTPResponse(response, type, resource, params)
        );
      } else return {data: [], total: 0}
    } else {
      return instance(url, options).then(response =>
        convertHTTPResponse(response, type, resource, params)
      );
    }
  };
};
