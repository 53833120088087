import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
} from 'react-admin';
import { get } from 'lodash';
import moment from 'moment';
import Actions from './Actions.js';

const SaleFilter = (props) => (
  <Filter {...props}>
    <TextInput label="По дате" source="saleDate" />
    <TextInput label="По ФИО продавца" source="fio" />
    <TextInput label="По коду партнера" source="code" />
    <TextInput label="По тарифу" source="tariff" />
    <TextInput label="По сумме" source="cost" />
    <TextInput label="По модели" source="model" />
    <TextInput label="По SN" source="sn" />
    <TextInput label="По статусу" source="status" />
  </Filter>
);

const FioField = ({ source, record = {} }) => {
  const data = get(record, source);
  return (
    <span>
      {`${data}`}
    </span>
  );
}

const TimeField = ({ source, record = {} }) => {
  const time = get(record, source);
  return (
    <span>
      {time}
    </span>
  );
}



export const ConfirmList = props => (
  <List
    title='Подтверждение изменений'
    actions={<Actions />}
    filters={<SaleFilter />}
    perPage={100000}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <TextField source='id' label='ID' />
      <TimeField source='saleDate' label='Дата/Время' />
      <FioField source='userName' label='ФИО продавца' />
      <TextField source='partnerCode' label='Код партнера' />
      <TextField source='plan' label='Тариф' />
      <TextField source='cost' label='Сумма' />
      <TextField source='' label='Модель' />
      <TextField source='serialNumber' label='SN' />
      <TextField source='status' label='Статус текущий' />
      <TextField source='potentialStatus' label='Статус после принятия' />
      <TextField source='statusComment' label='Коментарий системы' />
    </Datagrid>
  </List>
);
