import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { Responsive } from 'react-admin';
import { translate, userLogout as userLogoutAction } from 'ra-core';
import styled from 'styled-components';

export const LogoutBtn = styled.span`
  padding: 0 25px;
  cursor: pointer;
`;

const styles = theme => ({
  menuItem: {
    color: theme.palette.text.secondary,
  },
  iconMenuPaddingStyle: {
    paddingRight: '1.2em',
  },
  iconPaddingStyle: {
    paddingRight: theme.spacing.unit,
  },
});

const sanitizeRestProps = ({
  classes,
  className,
  translate,
  userLogout,
  locale,
  redirectTo,
  ...rest
}) => rest;

const Logout = ({ classes, className, translate, userLogout, ...rest }) => (
  <Responsive
    xsmall={
      <MenuItem
        onClick={userLogout}
        {...sanitizeRestProps(rest)}
      >
        Выход
      </MenuItem>
    }
    medium={
      <LogoutBtn
        onClick={userLogout}
        size="small"
        {...sanitizeRestProps(rest)}
      >
        Выход
      </LogoutBtn>
    }
  />
);

Logout.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  translate: PropTypes.func,
  userLogout: PropTypes.func,
  redirectTo: PropTypes.string,
};

const mapStateToProps = state => ({
  theme: state.theme,
});

const mapDispatchToProps = (dispatch, { redirectTo }) => ({
  userLogout: () => dispatch(userLogoutAction(redirectTo)),
});

const enhance = compose(
  translate,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
);

export default enhance(Logout);