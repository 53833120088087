import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter
} from 'react-admin';
import { ListActions } from '../common/ListActions';
import { ApproveButton } from '../common/Buttons';
import ApproveBulkButton from './ApproveBulkButton';
import { get } from 'lodash';

const SaleFilter = (props) => (
  <Filter {...props}>
    <TextInput label="По дате" source="saleDate" />
    <TextInput label="По ФИО продавца" source="fio" />
    <TextInput label="По коду партнера" source="code" />
    <TextInput label="По тарифу" source="tariff" />
    <TextInput label="По сумме" source="cost" />
    <TextInput label="По модели" source="model" />
    <TextInput label="По SN" source="sn" />
    <TextInput label="По статусу" source="status" />
  </Filter>
);

const PostBulkActionButtons = props => (
  <Fragment>
    <ApproveBulkButton {...props}/>
  </Fragment>
);

const FioField = ({ source, record = {} }) => {
  const data = get(record, source);
  return (
    <span>
      {`${data}`}
    </span>
  );
};

const TimeField = ({ source, record = {} }) => {
  const time = get(record, source);
  return (
    <span>
      {time}
    </span>
  );
};



export const SalesList = props => {
  return (
  <List
  title='Заведения'
  actions={<ListActions />}
  filters={<SaleFilter />}
  bulkActionButtons={<PostBulkActionButtons />}
  {...props}
  >
    <Datagrid rowClick='edit'>
      <TextField source='id' label='ID' />
      <TimeField source='saleDate' label='Дата/Время' />
      <FioField source='userName' label='ФИО продавца' />
      <TextField source='partnerCode' label='Код партнера' />
      <TextField source='plan' label='Тариф' />
      <TextField source='cost' label='Сумма' />
      <TextField source='checkSaleComment' label='Комментарий' />
      <TextField source='serialNumber' label='SN' />
      <TextField source='status' label='Статус' />
      <ApproveButton />
    </Datagrid>
  </List>
  );
}
