import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter, DateInput
} from 'react-admin';
import { get } from 'lodash';
import ListOfPayoutActions from './ListOfPayoutActions'

const SaleFilter = (props) => (
  <Filter {...props}>
    <DateInput alwaysOn label="По дате от" source="dateFrom" />
    <DateInput alwaysOn label="По дате до" source="dateTo" />
    <TextField label="По коду партнера" source="partnerCode" />
  </Filter>
);

const FioField = ({ source, record = {} }) => {
  const data = get(record, source);
  return (
    <span>
      {`${data}`}
    </span>
  );
};

const TimeField = ({ source, record = {} }) => {
  const time = get(record, source);
  return (
    <span>
      {time}
    </span>
  );
};

const DestinationField = ({ source, record = {} }) => {
  const data = get(record, source);
  return (
    <span>
      {`${ !data  ? '-'
        : data === 1 ? 'Атол Сигма'
          : 'Атол Сервис'}`}
    </span>
  );
}

export const StatisticsPayoutList = props => {
  return (
  <List
  title='Заведения'
  filters={<SaleFilter />}
  bulkActionButtons={false}
  actions={<ListOfPayoutActions/>}
  {...props}
  >
    <Datagrid>
      <TextField sortable={false} source='id' label='ID' />
      <TimeField sortable={false} source='saleDate' label='Дата/Время' />
      <TextField sortable={false} source='partnerCode' label='Код партнера' />
      <FioField sortable={false} source='userName' label='ФИО продавца' />
      <TextField sortable={false} source='amount' label='Сумма' />
      <TextField sortable={false} source='status' label='Статус' />
      <DestinationField sortable={false} source='destination' label='Счет выплаты' />
      <TextField sortable={false} source='comment' label='Примечание' />
    </Datagrid>
  </List>
  );
};
