import React, { Component } from 'react';
import { showNotification, UPDATE } from 'react-admin';
import RestProvider from '../../../Utils/RestProvider';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';

const restProvider = RestProvider('https://api.qrbonus.ru/admin/v1/');

class SubmitButton extends Component {
  handleClick = () => {
    const { record, basePath, redirect, resource, history } = this.props;
    const form_data = get(this.props, 'form.record-form.values', {});
    const updatedRecord = { ...form_data };
    const id = get(record, 'id', null);
    const rest_action = get(this.props, 'action', 'UPDATE');

    restProvider(rest_action, resource, { id, data: updatedRecord })
      .then(() => {
        showNotification('Comment approved');
        if (redirect) {
          history.push(redirect);
        } else {
          history.push(basePath);
        }
      })
      .catch((e) => {
        console.error(e);
        showNotification('Error: comment not approved', 'warning')
      });
  }

  render() {
    return (
      <Button 
        label="Approve" 
        onClick={this.handleClick}
        variant="contained" 
        color="primary"
      >
        Сохранить
      </Button>
    )
  }
}

function mapStateToProps(state) {
  return {
    form: state.form
  }
}

export default withRouter(connect(mapStateToProps)(SubmitButton));