import React from 'react';
import ListOfUsersActions from './ListOfUsersActions';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  DateInput,
} from 'react-admin';

const StatisticsFilter = (props) => (
  <Filter {...props}>
    <DateInput alwaysOn label="По дате от" source="dateFrom" />
    <DateInput alwaysOn label="По дате до" source="dateTo" />
  </Filter>
);

export const StatisticsList = props => (
    <List
      title='Статистика'
      filters={<StatisticsFilter/>}
      bulkActionButtons={false}
      actions={<ListOfUsersActions/>}
      {...props}
    >
      <Datagrid>
        <TextField source='id' sortable={false} label='ID'/>
        <TextField source='lastName' sortable={false} label='Фамилия'/>
        <TextField source='firstName' sortable={false} label='Имя'/>
        <TextField source='patronymic' sortable={false} label='Отчество'/>
        <TextField source='companyName' sortable={false} label='Название компании'/>
        <TextField source='balanceBonus' sortable={false} label='Баланс Бонус'/>
        <TextField source='balanceConnect' sortable={false} label='Баланс Коннект'/>
        <TextField source='partnerCode' sortable={false} label='Код партнера'/>
      </Datagrid>
    </List>
);
