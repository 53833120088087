import React from 'react';
import PropTypes from 'prop-types';
import {
  default as MaterialAppBar
} from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Headroom from 'react-headroom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { toggleSidebar as toggleSidebarAction } from 'ra-core';
import UserMenu from './UserMenu';
import config from '../../configs';
import { LogoContainer } from './styled';

const styles = theme => ({

});

function renderLogo(logo) {
  if (typeof logo === 'string' ) {
    return <Typography variant="display3" style={{paddingLeft: 100}} >{logo}</Typography>
  } else {
    return <LogoContainer>{logo}</LogoContainer>;
  }
}

const AppBar = ({
  toggleSidebar,
  width,
  ...rest
}) => (
  <Headroom>
    <MaterialAppBar
      position="static"
      color='secondary'
      {...rest}
    >
      <Toolbar
        disableGutters
        variant={width === 'xs' ? 'dense' : 'regular'}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleSidebar}
          style={{marginLeft: 3}}
        >
          <MenuIcon />
        </IconButton>
        {rest.renderLogo(config.logo)}
        <UserMenu {...rest}/>
      </Toolbar>
    </MaterialAppBar>
  </Headroom>
);

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.node,
  width: PropTypes.string,
};

const enhance = compose(
  connect(
    state => ({
      locale: state.i18n.locale,
    }),
    {
      toggleSidebar: toggleSidebarAction,
      renderLogo,
    }
  ),
  withStyles(styles),
  withWidth()
);

export default enhance(AppBar);