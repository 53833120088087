import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import FormControl from '@material-ui/core/FormControl';
import { organizationChanged as organizationChangedAction } from './OrganizationsMenu.action';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

class OrganizationsMenu extends Component {

  state = {
    organizations: [],
    organization: ''
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.props.organizationChanged(event.target.value);
    localStorage.currentOrg = event.target.value;
    this.props.history.push('/')
  };

  componentDidMount() {
    axios
      .get(`https://api.qrbonus.ru/v1/users/${localStorage.user_id}/organizations?limit=10&page=1&sid=${localStorage.sid}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((data) => {
        const items = get(data, 'data.items', []);
        if (items.length === 0) {
          this.props.history.push('/organizations/create');
          return;
        }
        this.setState({
          organizations: items,
          organization: items[0].organization_id
        });
        this.props.organizationChanged(items[0].organization_id);
        localStorage.currentOrg = items[0].organization_id;
      })
      .catch((err) => {
        console.log(err);
      })
  }

  render() {
    const { organizations } = this.state;
    return (
      <React.Fragment>
        <FormControl>
          <Select
            value={this.state.organization}
            onChange={this.handleChange}
            inputProps={{
              name: 'organization'
            }}
          >
            {
            organizations.map((el, i) => {
              return <MenuItem key={i} value={el.organization_id}>{el.name}</MenuItem>
            })
          }
          </Select>
        </FormControl>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentOrganization: state.currentOrganization,
    router: state.router
  }
}

export default withRouter(connect(mapStateToProps, {
  organizationChanged: organizationChangedAction
})(OrganizationsMenu));