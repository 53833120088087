import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  SelectInput,
  Filter
} from 'react-admin';
import get from 'lodash/get';

const choices = [
  {
    name: 'Атол Сигма',
    id: '1'
  },
  {
    name: 'Атол Сервис',
    id: '2',
  }
];

const DestinationField = ({ source, record = {} }) => {
  const data = get(record, source);
  return (
    <span>
      {`${ !data  ? '-' 
                  : data === 1 ? 'Атол Сигма' 
                               : 'Атол Сервис'}`}
    </span>
  );
}

const SaleFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="destination" choices={choices} alwaysOn label="По направлению" allowEmpty={true}>
    </SelectInput>
  </Filter>
);

export const AccrualsList = props => {
  return (
    <List
      title='Заведения'
      bulkActionButtons={false}
      actions={false}
      filters={<SaleFilter />}
      {...props}
    >
      <Datagrid>
        <TextField source='id' label='ID' sortable={false} />
        <TextField source='userProfile.companyName' label='Название' sortable={false} />
        <TextField source='fullName' label='Пользователь' sortable={false} />
        <TextField source='userProfile.id' label='ID пользователя' sortable={false} />
        <TextField source='amount' label='Выплата' sortable={false} />
        <DestinationField source='destination' label='Направление' sortable={false} />
        <TextField source='comment' label='Комментарий' sortable={false} />
      </Datagrid>
    </List>
  );
}
