import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {message, Spin } from "antd";
import Button from '@material-ui/core/Button';
import {SaleApprove} from './ApproveButton.action';
import axios from 'axios';
import config from "../../../configs";

class ShowVerificationButton extends Component {

  state = {
    loading: false,
    verificationStatus: ''
  };

  intervalForCheckVerification = '';

  componentDidMount() {
    this.intervalForCheckVerification =
      setInterval(() => {
        axios.get(`${config.baseUrl}/file/saleChecking/status`, {
            headers: {
              'Content-Type': 'application/json',
              'X-API-Key': localStorage.sid
            }
          })
          .then((resp) => {
            const status = resp.data;
            if (status === 'WORKING') this.setState({loading: true});
            else this.setState({loading: false});
          })
          .catch((err) => {
            console.log(err);
            message.error('error')
          })
      }, 1000)
  };

  componentWillUnmount() {
    clearInterval(this.intervalForCheckVerification);
  }

  goToLogAction = () => {
    if (!this.state.loading) {
      this.props.history.push('/sale/result/create')
    } else {
      return false
    }
  };

  render() {
    return (
      <Button
      primary
      variant='text'
      color='primary'
      size='small'
      style={{marginRight: 20, marginLeft: 20}}
      onClick={this.goToLogAction}
      disabled={this.state.loading}
      >
        <Spin spinning={this.state.loading}></Spin>
        {this.state.loading &&
          'Сверка формируется...'
        }
        {!this.state.loading &&
          'Предварительная сверка'
        }
      </Button>
    )
  }
}

ShowVerificationButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default connect(null, {
  SaleApprove
})(ShowVerificationButton);
